export const regexForEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const nameRegex = /^[A-Z a-z ]{1,50}$/;

export const mobileRegex = /^[0]?[56789]\d{9}$/;

export const bankAccountNumRegex = /^\d{9,18}$/;
export const ifscNumberRegec = /^[A-Z]{4}0[A-Z0-9]{6}$/;

export const pancardRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;

export const utmHelper = (arg: any) => {
  return arg instanceof Array || Array.isArray(arg) ? arg.join(",") : arg;
};

export const basePlanTitle: any = {
  "299-Mini-Plan": "Get a Health Plan with OPD Coverage - Kenko",
  "599-Individual-Plan":
    "Order Medicines Online With Discount & Home Delivery - Kenko",
  "999-Family-Plan":
    "Buy Family Floater Healthcare Plans, Group Protection Plans - Kenko",
  "The-Ultimate-Plan":
    "Best Health Plan, Flat 90% Off On Medicines, Doctor Fees, Lab Tests, Cover Hospital Bills | Kenko",
  "Senior-Citizen-Plan":
    "Health Plans for Senior Citizens, Low Cost Medicine and Discounts - Kenko",
  "Diabetes-Plan": "Buy Online Health Plan for Diabetes - Kenko",
  "OPD-Family-Plan":
    "Buy Online Family Health Care   Plan With OPD Benefits - Kenko",
  "OPD-Individual-Plan":
    "Buy Online Individual Health Care Plan With OPD Benefits - Kenko",
};

export const basePlanDescription: any = {
  "299-Mini-Plan":
    "Kenko’s basic plan with OPD coverage is perfect for medical emergencies without worrying about the cost. Get Kenko’s Mini healthcare plan that covers OPD expenses. Buy now!",
  "599-Individual-Plan":
    "With Kenko’s Individual Plan, you can get discounts on medicine with home delivery, doctor visits & lab test bookings. Get started at Rs. 599/month.",
  "999-Family-Plan":
    "Kenko offers affordable family healthcare plans starting at Rs. 999/month. It covers four family members & offers discounts on doctor fees, medicines, daily care & lab tests. Get it online.",
  "The-Ultimate-Plan":
    "Are you looking for the best family health plan to cover all your healthcare expenses? Choose Kenko’s The Ultimate Plan & get flat 90% discount on medicines, doctor fees, lab tests and also cover hospital bills. ",
  "Senior-Citizen-Plan":
    "Kenko’s senior citizen plan takes care of the healthcare needs of the elderly. Get it now & enjoy senior citizen discounts on medicines, doctor fees & lab tests.",
  "Diabetes-Plan":
    "Do you want to win the financial battle against Diabetes? Opt for Kenko’s Diabetes Care Plan to manage treatment costs & enjoy 50% OPD discounts. Get started now!",
  "OPD-Family-Plan":
    "Kenko offers OPD cover with family healthcare plans to cover daily medical expenses for a family of four. Starting at 849/month.",
  "OPD-Individual-Plan":
    "Need a healthcare plan that offers maximum coverage at affordable rates? Kenko provides OPD cover with individual healthcare plans starting at Rs. 449/month. Buy now!",
};

export const basePlanKeywords: any = {
  "299-Mini-Plan":
    "Opd Coverage, Basic Plan, Opd Plans, Opd Claim, Opd Expenses, Cashless Opd",
  "599-Individual-Plan":
    "Medicine Home Delivery, Medicine Order, Lab Test Booking, Lab Tests Discount, Individual Health Plans, Book Diagnostic Tests Online, Buy Medicines Online With Discount",
  "999-Family-Plan":
    "Family Healthcare Plans, Health Plan For Family, Family Floater Plan, Family Protection Plan, Group Health Plan",
  "The-Ultimate-Plan": "",
  "Senior-Citizen-Plan":
    "Health Care Plans For Senior Citizens, Senior Citizen Plan, Senior Citizen Discount On Medicines",
  "Diabetes-Plan":
    "Diabetes Care Plan, Diabetes Health Plan, Healthy Diet Plan For Diabetic Patient",
  "OPD-Family-Plan": "OPD cover with Family healthcare plan",
  "OPD-Individual-Plan": "OPD cover with Individual healthcare plan",
};

export const testimonialSchemaItems = [
  {
    id: 1,
    feedback:
      "Happy to receive constant support from The Kenko Team during an emergency. I'm glad to get reimbursed without any stress.",
    name: "Nilesh Kanherkar",
    avatarImg: "testimonial_section/Nilesh Kanherkar.webp",
    avatarImgLoading: "testimonial_section/Nilesh Kanherkar small.webp",
    rating: 5,
  },
  {
    id: 2,
    feedback:
      "The customer delight team at Kenko was really helpful during the entire treatment process for my mother. Appreciate their thorough professionalism.",
    name: "Shivam Singhania",
    avatarImg: "testimonial_section/Shivam Singhania.webp",
    avatarImgLoading: "testimonial_section/Shivam Singhania small.webp",
    rating: 5,
  },
  {
    id: 3,
    feedback:
      "Special shoutout to Team Kenko. My mother was hospitalised for minor treatment and Kenko transferred my benefits immediately. I immensely appreciate their efforts.",
    name: "Shreekanth Sampigethaya",
    avatarImg: "testimonial_section/Shreekanth Sampigethaya.webp",
    avatarImgLoading: "testimonial_section/Shreekanth Sampigethaya small.webp",
    rating: 5,
  },
  {
    id: 4,
    feedback:
      "Kenko is the Best Family Health Plan I have come across. They helped me throughout my illness. Their quick response was commendable.",
    name: "Vitthal Sitaram",
    avatarImg: "testimonial_section/Vitthal Sitaram.webp",
    avatarImgLoading: "testimonial_section/Vitthal Sitaram small.webp",
    rating: 5,
  },
  {
    id: 5,
    feedback:
      "Kenko reps were very helpful and cooperative in assisting me with my subscription. Overall an excellent experience.",
    name: "Ajit More",
    avatarImg: "testimonial_section/Ajit More.webp",
    avatarImgLoading: "testimonial_section/Ajit More small.webp",
    rating: 5,
  },
  {
    id: 6,
    feedback:
      "I am delighted with Kenko's service. They provided me with a perfect blend of compassion and competence.",
    name: "Naveen Prasad Dutta",
    avatarImg: "testimonial_section/Naveen Prasad Dutta.webp",
    avatarImgLoading: "testimonial_section/Naveen Prasad Dutta small.webp",
    rating: 5,
  },
  {
    id: 7,
    feedback:
      "I had all my benefits processed within two days. I would like to thank Kenko for their prompt service and quick thinking of their customer delight team.",
    name: "Anitha Chaduvula",
    avatarImg: "testimonial_section/Anitha Chaduvula.webp",
    avatarImgLoading: "testimonial_section/Anitha Chaduvula small.webp",
    rating: 5,
  },
  {
    id: 8,
    feedback:
      "Exceptional experience with Kenko's team. They are polite and cooperative. Got 100% reimbursement for my mom's treatment from Kenko without any hassle.",
    name: "Abdul Sayyed",
    avatarImg: "testimonial_section/Abdul Sayyed.webp",
    avatarImgLoading: "testimonial_section/Abdul Sayyed small.webp",
    rating: 5,
  },
];
