import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button as MuiButton } from "@mui/material";
import styled from "@emotion/styled";
import Button from "../../../shared/components/Button/Button";
import Typography from "../../../shared/components/Typography/Typography";
import OTPInput from "../../common/OtpInput";
import {
  verifyOtpAndLogin,
  resendOTP,
  clearSendOTP,
  fetchIpAddress,
} from "../../../slices/auth-slice";
import {
  PURCHASE_PATH_DEPENDENTS,
  SIGNUP_PAGE,
} from "../../../shared/constants/routes";
import { useRedirect, useRouter } from "../../../shared/hooks";
import { kenkoOrange } from "../../../shared/assets/colors";
import { setPixelFired, signupSuccess } from "../../../slices/tracker.slice";
import Moengage from "../../../shared/utils/moengage.utils";
import * as ga from "../../../shared/utils/ga.utils";
import { utmHelper } from "../../../helpers";
import { getDsaParams } from "../../Onboarding/onboarding.utils";

const OtpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .form-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .heading {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #212121;
    margin-bottom: 30px;

    .mobile-number {
      font-weight: 600;
    }

    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 15px;
      text-align: center;
    }
  }

  .subheading {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #9d9d9d;
    margin-top: 20px;

    .resend-otp {
      text-decoration-line: underline;
      text-transform: capitalize;
      padding: 0;
      font-size: 12px;
      font-weight: 500;
      color: #2f6d6e;

      @media (max-width: 600px) {
        font-size: 11px;
      }
    }

    .resend-otp:disabled {
      opacity: 0.5;
      pointer-events: unset !important;
      cursor: not-allowed;
    }

    .resend-otp:hover {
      background-color: unset;
    }

    @media (max-width: 600px) {
      font-size: 11px;
      margin-top: 10px;
      text-align: center;
    }
  }

  .otp-input {
    display: flex;
    gap: 12px;

    @media (max-width: 600px) {
      justify-content: center;
      gap: 0;
    }
  }

  .resend-otp-msg {
    font-size: 15px;
    margin-top: 10px;
    color: #2f6d6e;

    @media (max-width: 600px) {
      font-size: 11px;
      text-align: center;
      margin-top: 0;
    }
  }

  .error-msg {
    font-size: 14px;
    margin-top: auto;
    margin-bottom: 10px;
    color: ${kenkoOrange};

    p {
      margin: 0;
    }

    @media (max-width: 600px) {
      font-size: 11px;
      text-align: center;
    }
  }

  .error-wrapper {
    display: flex;
    margin-top: auto;

    @media (max-width: 600px) {
      justify-content: center;
    }
  }

  .verify-btn {
    display: flex;
    justify-content: center;
    height: 48px;
    width: 100%;
    font-size: 16px !important;
    box-shadow: unset !important;

    @media (max-width: 600px) {
      font-size: 14px !important;
    }
  }

  .incorrect-mobile {
    text-decoration-line: underline;
    text-transform: inherit;
    padding: 0;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #2f6d6e;

    @media (max-width: 600px) {
      font-size: 11px;
    }
  }
`;

const MobileOtp = ({
  setInputFocused,
  dialogClose,
  handleNewUser,
  parent,
  otpError,
}: any) => {
  const { sendOTP, ipAddress, phoneLogin } = useSelector(
    (state: any) => state.auth
  );
  const { groupId } = useSelector((state: any) => state.onboarding);
  const utm = useSelector((state: any) => state.utmReducer);

  const dispatch = useDispatch<any>();

  const router = useRouter();

  const initialTimer = { mins: 0, seconds: 59 };

  const [error, setError] = useState<any>({});
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(initialTimer);

  const [clientId, setClientId] = useState(null);

  const [touched, setTouched] = useState(false);

  const { utm_source, utm_medium, utm_campaign } = useSelector(
    (state: any) => state.utmReducer
  );

  useRedirect({
    redirect: true,
  });

  useEffect(() => {
    if (typeof window !== "undefined") ga.findClientId(setClientId);

    dispatch(fetchIpAddress());
  }, [dispatch]);

  useEffect(() => {
    setTouched(true);
  }, [otp]);

  useEffect(() => {
    if (phoneLogin?.isError) {
      setTouched(false);
    }
  }, [phoneLogin?.isError]);

  const handleIncorrectMobileNumber = () => {
    setInputFocused(true);
    dialogClose(false);
    dispatch(clearSendOTP());
  };

  const handleResendOtp = () => {
    setTouched(false);

    dispatch(
      resendOTP({
        phone: sendOTP?.phone,
        type: sendOTP?.type,
      })
    )
      .unwrap()
      .then(() => {
        // Clear otp error after resend otp
        setError({});

        window?.Moengage?.track_event(
          "web_otp_verification_otp_resent_successfully",
          {
            mobile_number: sendOTP?.phone,
          }
        );
        ga.event({
          action: "web_otp_verification_otp_resent_successfully",
          params: {
            mobile_number: sendOTP?.phone,
          },
        });
      });

    setTimer(initialTimer);
  };

  const handleVerifyOTP = (e: any) => {
    e?.preventDefault();

    setTouched(false);

    dispatch(
      verifyOtpAndLogin({
        phoneNo: sendOTP?.phone,
        augmentedHash: sendOTP?.augmentedHash,
        otp: otp || "",

        client_id: clientId,
        whatsappOptIn: false,
        platformPlusTimestamp:
          navigator.platform + ":" + navigator.vendor + ":" + Date.now(),
        versionSource: "FHC",
        ipAddress: ipAddress,
        signupSource: "WEB_APP",

        utm_campaign: utm?.utm_campaign || utm?.c || "",
        utm_medium: utm?.utm_medium || utm?.medium || "",
        utm_source: utm?.utm_source || utm?.af_xp || "",
        ...getDsaParams(utm),
      })
    )
      .unwrap()
      .then(({ data }: any) => {
        if (data?.newUser) {
          // New user => Signup

          dispatch(setPixelFired(true));
          Moengage.pushEvent("web_new_user_signup", {
            phoneNo: sendOTP?.phone,
          });

          // Create lead on verifying otp
          window?.Moengage?.track_event("web_lead_created_signup", {
            utmSource: utmHelper(utm_source),
            utmMedium: utmHelper(utm_medium),
            utmCampaign: utmHelper(utm_campaign),
            device: window.screen.availWidth < 600 ? "mobile" : "desktop",
          });

          if (groupId) {
            router.push(PURCHASE_PATH_DEPENDENTS);
          }

          if (parent == "signup-dialog") {
            handleNewUser(data.lead?.id);
          } else {
            router.push(SIGNUP_PAGE);
          }
        } else {
          // Existing user => Login
          Moengage.pushEvent("web_returning_user_signup", {
            phoneNo: sendOTP?.phone,
          });

          window?.Moengage?.track_event(
            "web_user_logged_in_via_mobile_no_via_signup",
            {
              mobile_number: sendOTP?.phone,
            }
          );
          ga.event({
            action: "web_user_logged_in_via_mobile_no_via_signup",
            params: {
              mobile_number: sendOTP?.phone,
            },
          });

          window?.Moengage?.add_unique_user_id(
            data.data?.user?.hubspotContactId
          );
          window?.Moengage?.add_first_name(data.data?.user?.name.split(" ")[0]);
          window?.Moengage?.add_last_name(
            data.data?.user?.name.split(" ").slice(1).join(" ")
          );
          window?.Moengage?.add_mobile(data.data?.user?.phoneNo);
          window?.Moengage?.add_email(data.data?.user?.emailId);
          Moengage.pushEvent("web_login_success", {
            hubspotId: data?.data?.user?.hubspotContactId,
            email: data?.data?.user?.emailId,
            name: data?.data?.user?.name,
            mobileNum: data?.data?.user?.phoneNo,
            age: data?.data?.user?.age,
            gender: data?.data?.user?.gender,
            userStatus: data?.data?.user?.userStatus,
            userCategory: data?.data?.user?.category,
            pincode: data?.data?.user?.pincode,
          });

          dispatch(signupSuccess());
        }
      })
      .catch((error: any) => {
        console.log(error);
        setError(error);
      });
  };

  const getMobileNumber = (mobile: string) =>
    mobile?.slice(0, 5) + " " + mobile?.slice(5);

  const getTimerValue = (timer: string) =>
    timer?.length === 2 ? timer : `0${timer}`;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTimer((prev) => ({
        mins: prev.seconds === 0 ? prev.mins - 1 : prev.mins,
        seconds: prev.seconds > 0 ? prev.seconds - 1 : 59,
      }));
    }, 1000);

    if (timer?.mins === 0 && timer?.seconds === 0) {
      clearInterval(timeoutId);
    }

    return () => clearInterval(timeoutId);
  }, [timer]);

  return (
    <OtpWrapper>
      <Typography className="heading">
        Enter the 6 digit OTP sent to <br />
        <span className="mobile-number">
          {getMobileNumber(sendOTP?.phone?.toString())}
        </span>
      </Typography>

      <form onSubmit={handleVerifyOTP} className="form-wrapper">
        <div className="otp-input">
          <OTPInput
            numberOfInputs={6}
            value={otp}
            setValue={setOtp}
            error={false}
            success={true}
          />
        </div>

        {sendOTP?.resent && (
          <Typography className="resend-otp-msg">
            OTP has been sent successfully.
          </Typography>
        )}

        <Typography className="subheading">
          Didn’t receive the OTP?{" "}
          <MuiButton
            variant="text"
            className="resend-otp"
            onClick={handleResendOtp}
            disabled={timer.mins !== 0 || timer.seconds !== 0}
          >
            Resend OTP
          </MuiButton>{" "}
          in {getTimerValue(timer?.mins?.toString())}:
          {getTimerValue(timer?.seconds?.toString())}
        </Typography>

        <div className="error-wrapper">
          <Typography className="error-msg">
            {error && Object.keys(error)?.length > 0 ? (
              <>
                {error?.errMsg ? <p>Error: {error?.errMsg}</p> : null}
                {error?.respId ? <p>Ref Id: {error?.respId}</p> : null}
              </>
            ) : null}

            {sendOTP?.resendError}

            {otpError && Object.keys(otpError)?.length > 0 ? (
              <>
                {otpError?.errMsg ? <p>Error: {otpError?.errMsg}</p> : null}
                {otpError?.respId ? <p>Ref Id: {otpError?.respId}</p> : null}
              </>
            ) : null}
          </Typography>
        </div>

        <Button
          label="Verify"
          type="submit"
          className="verify-btn"
          disabled={
            !touched ||
            // sendOTP?.isLoading ||
            // phoneLogin?.isLoading ||
            otp.length !== 6
          }
          debounceDuration={400}
        />
      </form>

      <MuiButton
        variant="text"
        onClick={handleIncorrectMobileNumber}
        className="incorrect-mobile"
      >
        <Typography>Entered incorrect phone number? Click here!</Typography>
      </MuiButton>
    </OtpWrapper>
  );
};

export default MobileOtp;
