import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getConfig, getPlanBreakup } from "./../../slices/onboarding.slice";

// type usePurchaseProps = {};

export const usePurchasePath = () => {
  const dispatch = useDispatch<any>();

  const { userInfo } = useSelector((state: any) => state.auth);
  const { groupId, dependents, couponCode } = useSelector(
    (state: any) => state.onboarding
  );
  const [initialLoad, setInitialLoad] = useState(false);

  // const { isConfigLoading, config, isConfigError } = useSelector(
  //   (state: any) => state.onboarding
  // );
  // const { isPlanBreakupLoading, planBreakup, isPlanBreakupError } = useSelector(
  //   (state: any) => state.onboarding
  // );

  const { isConfigLoading, isPlanBreakupLoading } = useSelector(
    (state: any) => state.onboarding
  );

  useEffect(() => {
    if (!groupId) setInitialLoad(true);
  }, []);

  useEffect(() => {
    if (!userInfo?.userId || !groupId) return;

    dispatch(getConfig({ groupId }));
    dispatch(getPlanBreakup({ groupId }));

    setInitialLoad(true);
  }, []);

  useEffect(() => {
    if (!userInfo?.userId || !groupId) return;

    if (!isConfigLoading) dispatch(getConfig({ groupId }));

    if (!isPlanBreakupLoading)
      dispatch(getPlanBreakup({ groupId, dependents, couponCode }));
  }, [userInfo?.userId]);

  useEffect(() => {
    if (!userInfo?.userId || !groupId) return;

    if (!isPlanBreakupLoading)
      dispatch(getPlanBreakup({ groupId, dependents, couponCode }));
  }, [dependents, couponCode]);

  useEffect(() => {
    if (!userInfo?.userId || !groupId) return;

    dispatch(getConfig({ groupId }));
    dispatch(getPlanBreakup({ groupId, dependents, couponCode }));
  }, [groupId]);

  return {
    initialLoad,
  };
};

interface dsaTypes {
  sales_agent?: string;
  sales_channel?: string;
}

const dsaProperties: dsaTypes = {};

export const getDsaParams = (utm: any) => {
  if (utm?.sales_agent) dsaProperties.sales_agent = utm?.sales_agent;
  if (utm?.sales_channel) dsaProperties.sales_channel = utm?.sales_channel;

  return dsaProperties;
};

export const isDsaPlan = (dsaPlan: string) => {
  return dsaPlan?.toLowerCase()?.includes("dsa");
};
