import { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { InputAdornment, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { validatePhoneNumber } from "../Login/Input/input.validators";
import * as ga from "../../shared/utils/ga.utils";
import Typography from "../../shared/components/Typography/Typography";
import { kenkoOrange } from "../../shared/styles/colors";

const MobileInputWrapper = styled.div`
  position: relative;
  font-family: "Poppins";
  margin-bottom: 40px;

  @media (max-width: 600px) {
    margin-bottom: 32px;
  }

  .input-wrapper {
    width: 100%;
    font-family: "Figtree", sans-serif !important;
  }

  & .MuiOutlinedInput-root.Mui-focused {
    border-radius: 8px;

    & > fieldset {
      border: 1px solid #003032 !important;
    }
  }

  & .MuiInputBase-root {
    outline: none !important;
    border-radius: 8px;
    height: 48px;
    gap: 16px;
    font-family: "Poppins";

    @media (max-width: 600px) {
      height: 48px;
    }
  }

  & .MuiInputAdornment-root {
    margin-right: 0;
  }

  & .MuiInputBase-input {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #1a1a1a;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  .input-prefix p {
    color: #363636;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    font-family: "Poppins";

    ::placeholder {
      color: #b8b8b8 !important;
      opacity: 1;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
    }

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid #d9d9d9 !important;
    }
  }

  .error-msg {
    color: #f33221;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    margin-top: 8px;

    @media (max-width: 600px) {
      font-size: 11px;
      margin-top: 4px;
    }
  }
`;

const MobileInputHomeWrapper = styled.div`
  position: relative;

  .input-wrapper {
    width: 100%;
    background: #ffffff;
    border: 1px solid #d6d9dc;
    border-radius: 8px;

    outline: none !important;
  }

  .MuiFormControl-root:focus {
    outline: 1px solid #9ebf76 important;
    border-radius: 8px;
  }

  & .MuiInputBase-root {
    outline: none !important;
    height: 52px;
  }

  & .MuiInputBase-input,
  .input-prefix p {
    color: #1a1a1a !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 18px !important;

    ::placeholder {
      color: #737373 !important;
      font-family: "Figtree", sans-serif !important;
      opacity: 1;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      align-items: center;
    }
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border: 1px solid #d9d9d9 !important;
    }
  }

  .error-msg {
    color: ${kenkoOrange};
    font-size: 12px;
    position: absolute;
    margin-top: 8px;

    @media (max-width: 600px) {
      font-size: 11px;
    }
  }
`;

const MobileInput = ({
  setInputFocused,
  mobile,
  setMobile,
  error,
  setError,
  onClick,
  parent,
}: {
  setInputFocused: Function;
  mobile: string;
  setMobile: Function;
  error: string;
  setError: Function;
  onClick: any;
  parent: string;
}) => {
  const inputRef = useRef();

  const changeHandler = (value: any) => {
    if (value.toString().length <= 10) {
      setError(validatePhoneNumber(value));

      setMobile(value);
    }
  };

  useEffect(() => {
    if (mobile && mobile?.toString().length === 10) {
      window?.Moengage?.track_event("web_mobile_entered", {
        mobile_number: mobile,
      });
      ga.event({
        action: "web_mobile_entered",
        params: {
          mobile_number: mobile,
        },
      });
    }
  }, [mobile]);

  return (
    <>
      {parent === "home-page" ? (
        <MobileInputHomeWrapper>
          <div>
            <TextField
              // type="number"
              // autoFocus
              inputProps={{ pattern: "[0-9]*" }}
              placeholder="Continue with Mobile Number"
              value={mobile}
              onChange={(e) => changeHandler(e?.target?.value)}
              className="input-wrapper"
              // label="Mobile Number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="input-prefix">
                    +91
                  </InputAdornment>
                ),
              }}
              onFocus={() => setInputFocused(true)}
              inputRef={inputRef}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: error ? "1px solid #FF7056 !important" : "initial",
                    borderRadius: "8px!important",
                  },
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                  "& > fieldset": {
                    border: error ? "1px solid #FF7056 !important" : "initial",
                    borderRadius: "8px!important",
                  },
                },
              }}
            />
          </div>

          {error ? (
            <Typography className="error-msg">{error}</Typography>
          ) : null}
        </MobileInputHomeWrapper>
      ) : (
        <MobileInputWrapper>
          <div>
            <TextField
              type="text"
              inputProps={{ pattern: "[0-9]*" }}
              placeholder="Continue with your phone number"
              value={mobile}
              onChange={(e) => changeHandler(e?.target?.value)}
              className="input-wrapper"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className="input-prefix">
                    +91
                  </InputAdornment>
                ),
              }}
              onFocus={() => setInputFocused(true)}
              onClick={onClick}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: error ? "1px solid #F33221 !important" : "initial",
                  },
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                  "& > fieldset": {
                    border: error ? "1px solid #F33221 !important" : "initial",
                  },
                },
              }}
            />
          </div>

          {error ? (
            <Typography className="error-msg">{error}</Typography>
          ) : null}
        </MobileInputWrapper>
      )}
    </>
  );
};

MobileInput.propTypes = {
  setInputFocused: PropTypes.func.isRequired,
  mobile: PropTypes.string,
  setMobile: PropTypes.func,
  error: PropTypes.string,
  setError: PropTypes.func,
  onClick: PropTypes.func,
  parent: PropTypes.string,
};

export default MobileInput;
