import { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import * as ga from "../../shared/utils/ga.utils";

const InputWrapper = styled.input`
  font-size: 17px;
  font-weight: 600;
  color: rgba(0, 48, 50, 1);
  text-align: center;
  font-family: Poppins;

  border: 1px solid #d9d9d9;
  outline: 0;
  border-radius: 8px;
  box-shadow: none;

  width: 45px;
  height: 45px;

  padding: 10px;

  :focus {
    border-color: #9ebf76;
  }

  border-color: ${(props: any) =>
    (props?.error && "rgba(255, 112, 86, 1)") || (props?.success && "#003032")};

  @media (max-width: 600px) {
    font-size: 16px;
    max-width: 40px;
    padding: 5px;
    margin: 20px 5px;
  }
`;

const OTPInput = ({
  numberOfInputs,
  value,
  setValue,
  error,
}: {
  numberOfInputs: number;
  value: any;
  setValue: Function;
  error: boolean;
  success: boolean;
}) => {
  const [otp, setOTP] = useState(new Array(numberOfInputs).fill(""));
  const [lock, setLock] = useState(false);

  // Initialise the OTP Input with the value passed in
  useEffect(() => {
    if (!value) return;

    if (isNaN(value)) return;

    const newOtp = [...value?.split(""), ...otp.slice(value.length)];
    setOTP(newOtp);
  }, []);

  // Initialise the OTP Input with the value passed in
  useEffect(() => {
    if (!value) {
      setOTP(new Array(numberOfInputs).fill(""));
      return;
    }

    if (isNaN(value)) return;

    if (value === otp.join("")) return;

    const newOtp = [...value?.split(""), ...otp.slice(value.length)];
    setOTP(newOtp);
  }, [value]);

  // Update the value with current value of the OTP
  useEffect(() => {
    setValue(otp.join(""));
  }, [otp]);

  // Handle value updates into the input
  const handleChange = (e: any, index: number) => {
    if (!e.target.value.trim() || isNaN(e.target.value)) return;

    if (otp?.join("")?.toString()?.length === 5) {
      window?.Moengage?.track_event("otp_entered", {
        otp,
      });
      ga.event({
        action: "otp_entered",
        params: {
          otp,
        },
      });
    }

    const newOTP = [
      ...otp?.slice(0, index),
      e.target.value,
      ...otp?.slice(index + 1),
    ];

    // if (newOTP[5] !== "") {
    //   pathName === "/kenko-score/my-details" &&
    //     window?.Moengage?.track_event("web_signup_verify_otp_clicked", {
    //       device: window.screen.availWidth < 600 ? "mobile" : "desktop",
    //     });
    // }
    setOTP(newOTP);
  };

  // Handles switching between input fields
  const handleKeyPress = (e: any, index: number) => {
    // Handles Back Button Presses

    if (e.keyCode === 8 || e.charCode === 8) {
      let newOTP = [...otp];

      if (lock) {
        newOTP = [...otp.slice(0, index), "", ...otp.slice(index + 1)];
        setLock(false);
      }

      if (!lock && !otp?.[index]) {
        newOTP = [
          ...otp.slice(0, (index && index - 1) || 0),
          "",
          ...otp.slice((index > 0 && index) || 1),
        ];

        e.target?.previousSibling?.focus();
      }

      // Updates emptied values to ""
      setOTP(newOTP);
    } else if (otp?.[index] && !isNaN(otp?.[index])) {
      e.target?.nextSibling?.focus();
      setLock(index + 1 != numberOfInputs - 1);
    }
  };

  const inputRef = useRef();

  useEffect(() => {
    // Set focus on first input
    // @ts-ignore
    inputRef.current?.focus();
  }, []);

  return (
    <>
      {otp?.map((item, index) => {
        return (
          <InputWrapper
            key={"otp-input-" + index}
            style={{
              borderColor: !error && item && "#9ebf76",
            }}
            inputMode="numeric"
            pattern="[0-9]*"
            // @ts-ignore
            ref={index === 0 ? inputRef : null}
            type="text"
            value={item}
            maxLength={1}
            onChange={(e) => handleChange(e, index)}
            onClick={() => setLock(true)}
            onFocus={(e) => e.target.select()}
            onKeyUp={(e: any) => handleKeyPress(e, index)}
          />
        );
      })}
    </>
  );
};

export default OTPInput;
