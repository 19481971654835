export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const phoneRegex = /^[0]?[56789]\d{9}$/;

import { regexForEmail } from "../../../helpers";

export const validatePhoneNumber = (value: number) => {
  if (!value) return "Please provide a valid phone number";

  if (isNaN(value)) return "Please provide a valid phone number";

  if (!phoneRegex.test(value?.toString()))
    return "Please provide a valid phone number";

  return null;
};

export const validateEmail = (email: string) => {
  console.log("email validation called");
  console.log(regexForEmail.test(email), "regex");

  if (regexForEmail.test(email)) {
    return null;
  } else if (email.trim() === "") {
    return "Email is required";
  } else return "Please provide a valid email ID";
};

export const validatePassword = (value: string) => {
  if (!value) return "Password cannot be empty.";

  if (value?.length < 8) return "Password must be at least 8 characters.";

  return null;
};
