export const ORANGE = "#FF7056";
export const YELLOW = "#FBCE60";
export const BLUE = "#C2E6E0";
export const WHITE = "#EFEDD8";
export const PURE_WHITE = "#FFFFFF";

export const BLACK = "#003032";
export const L_GREEN = "#BAD19D";
export const D_GREEN = "#7F9E6D";
export const GRAY_GREEN = "#859862";

// styles for homepage
export const kenkoStraw = "#EFEDD8";
export const kenkoDeepTeal = "#003032";
export const kenkoGolden = "#FBCE60";
export const kenkoOrange = "#FF7056";
export const kenkoSprout = "#BAD19D";
export const kenkoSprout200 = "#BED0A2";
export const kenkoAvocado = "#859862";
export const lightStraw = "#f6f5eb";
export const kenkoPureWhite = "#ffffff";
export const lightSprout = "#b4c688";
export const lightBg = "#fbfbf6";
export const neutralBg = "#f5f5f5";
export const neutralLightBg = "#f5f5f580";
export const appleGreen50 = "#eef4ef";
export const mossGreen50 = "#F4F7ED";
export const mossGreen100 = "#E3EBD2";
export const kenkoCarrot = "#EFEDDA";

export const addOpacity = (color: any) => (opacity: any) => color + opacity;

export default {
  ORANGE,
  YELLOW,
  BLUE,
  WHITE,
  BLACK,
  L_GREEN,
  D_GREEN,
  GRAY_GREEN,
  PURE_WHITE,
  addOpacity,
};
